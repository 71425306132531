import React from 'react';
import '../index.css';

// Importe todas as imagens necessárias
import AstreaLogo from '../img/Apoios/AstreaLogo.png';
import ContabilizeiLogo from '../img/Apoios/ContabilizeiLogo.png';
import GoogleWorkspaceLogo from '../img/Apoios/GoogleWorkspaceLogo.png';
import NotificaLogo from '../img/Apoios/NotificcaLogo.png';
import VerifctLogo from '../img/Apoios/VerifectLogo.png';
import WeTransferLogo from '../img/Apoios/WeTranferLogo.png';
import ZapSignLogo from '../img/Apoios/ZapSignLogo.png';

const Ferramentas = () => {
    return (
        <div className="carousel-section">
            <h2 className='titulos-azul'>Ferramentas</h2>
            <div className="carousel-container slider" style={{ "--width": "200px", "--height": "200px", "--quantity": "7" }}>
                <div className="list">
                    <div className="item" style={{ "--position": 1 }}><img src={AstreaLogo} alt="Astrea Logo" /></div>
                    <div className="item" style={{ "--position": 2 }}><img src={ContabilizeiLogo} alt="Contabilizei Logo" /></div>
                    <div className="item" style={{ "--position": 3 }}><img src={GoogleWorkspaceLogo} alt="Google Workspace Logo" /></div>
                    <div className="item" style={{ "--position": 4 }}><img src={NotificaLogo} alt="Notifica Logo" /></div>
                    <div className="item" style={{ "--position": 5 }}><img src={VerifctLogo} alt="Verifct Logo" /></div>
                    <div className="item" style={{ "--position": 6 }}><img src={WeTransferLogo} alt="WeTransfer Logo" /></div>
                    <div className="item" style={{ "--position": 7 }}><img src={ZapSignLogo} alt="ZapSign Logo" /></div>
                </div>
            </div>
        </div>
    );
}

export default Ferramentas;
